<template>
  <div class="app-container">
    <div class="top">
      <div class="top-item">
        <h4 class="top-item-left">
          按职业名称查找
        </h4>
        <div class="top-item-right">
          
          <el-autocomplete
              class="top-item-right-input"
              v-model="searchOccupation"
              value-key="ProfessionName"
              :fetch-suggestions="querySearchOccupation"
              placeholder="请输入职业名称"
              :trigger-on-focus="false"
              @select="selectOccupation"
            ></el-autocomplete>
            <el-button class="top-item-right-button" type="primary" @click="showSearchOccupation()">搜索</el-button>
        </div>
      </div>
      <div class="top-item">
        <search-profession-type  @ok="SearchOccupationlist"></search-profession-type>
      </div>
    </div>
    <div class="middle">
      <h4 class="middle-title">职业分类</h4>
      <div class="middle-box">
        <div class="middle-box-item" :class="!((index+1)%2)&&'deepenBackground'" v-for="(item, index) in datalist" :key="index">
          <div class="middle-box-item-list">
            <div class="middle-box-item-list-item" v-for="(subItem, subIndex) in item.Children" :key="subIndex" @click="goToList(subItem)">
              {{subItem.CategoryName}}
            </div>
          </div>
          <div>
          <img class="middle-box-item-img" :src="item.imgurl">

          </div>
          <h4 class="middle-box-item-title">{{item.CategoryName}}</h4>
        </div>
      </div>
    </div>
    <div class="bottom">
      <hot-profession></hot-profession>
    </div>
  </div>
</template>

<script>
  // import { CategoryList,ProfessionSearchByName } from '@/api/career/profession'
  import API from '@/api/config'

  import SearchProfessionType from '@/views/tool/profession/local-components/SearchProfessionType'
  import HotProfession from '@/views/tool/profession/local-components/HotProfession'
  export default {
    name: "Search-Profession",
    components:{
      SearchProfessionType,
      HotProfession
    },
    data(){
      return{
        datalist:[],
        searchOccupation:''
      }
    },
    mounted(){
      this.initialize()
    },
    methods:{
      //
      initialize() {
      API.Profession.CategoryList().then(res => {
        this.datalist = res.data.map((item, index) => {
          item.intrduce = false;
          item.imgurl = require('@/assets/tool/search-profession/type'+(index+1)+'.png');
          return item;
        });
      });
      },
        // 兴趣代码搜索
    SearchOccupationlist(val) {
      this.$router.push({
        path: "/profession-typelist",
        query: { typecode: val }
      });
    },
    goToList(val) {
      this.$router.push({
        path: "/profession-list",
        query: { CategoryId: val.ID, CategoryName: val.CategoryName }
      });
    },
     querySearchOccupation(queryString, cb) {

      API.Profession.ProfessionSearchByName({
        Name: queryString,
        PageIndex: 1,
        PageSize: 99
      }).then(res => {

        cb(res.data.Data);
      });
    },
    selectOccupation(val) {
      this.$router.push({
        path: "/profession-detail",
        query: { ID: val.ID }
      });
    },

    showSearchOccupation() {
      if (this.searchOccupation == "") {
        this.$message({
          showClose: true,
          message: "请输入你需要搜索的内容"
        });
        return;
      }
      this.$router.push({
        path: "/profession-seachlist",
        query: { Name: this.searchOccupation }
      });
    }
    }
  }
</script>

<style scoped lang="less">
  .top {
    box-sizing: border-box;
    width: 65rem;
    margin: 2rem auto;
    padding: 5rem;

    background-color: #f4f7fb;
    &-item {
      margin-bottom: 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-left {
        color: var(--text-font-color);
      }
      &-right {
        display: flex;
        &-input {
          width: 35rem;
        }
        &-button {
          width: 6rem;
          margin-left: 8px;
        }
      }
    }
  }
  .middle{
    &-title{
      text-align: center;
      color: var(--text-font-color);
      font-size: var(--title-font-size);
      margin-bottom: 3rem;
    }
    &-box{
      display: flex;
      flex-wrap: wrap;
      &-item{
        box-sizing: border-box;
        width: calc(100% / 3);
        height: 13rem;
        padding: 1.5rem;
        text-align: center;
        position: relative;
        &-list{
          box-sizing: border-box;
          width: 100%;
          height: 100%;
          padding: 2rem;

          display: flex;
          align-content: flex-start;
          flex-wrap: wrap;
          position: absolute;
          top: 0;
          left: 0;
          &:hover{
            background-color: rgba(var(--themecolor), 0.4);
            .middle-box-item-list-item{
              display: block;
            }
          }
          &-item{
            display: none;
            margin: 0 1rem .5rem 0;
            font-size: var(--text-font-size);
            color: #fff;
            &:hover{
              cursor: pointer;
              color: rgba(var(--themecolor));
            }
          }
        }
        &-img{}
        &-title{
          margin-top: 1rem;
        }
      }
      .deepenBackground{
        background-color: rgba(var(--themecolor),0.1);
      }
    }
  }
  .bottom{
    padding: 2rem 0;
  }

</style>
<style lang="less">
.app-container .el-button--primary,.app-container .el-button--primary:focus{
  background: rgba(var(--themecolor),0.75);
  border-color: rgba(var(--themecolor),0.75);
}
.app-container .el-button--primary:hover{
  background: rgba(var(--themecolor),1);
  border-color: rgba(var(--themecolor),1);
}
.app-container .el-select .el-input.is-focus .el-input__inner{
  border-color: rgba(var(--themecolor),0.75);
}
.app-container .el-select .el-input__inner:focus,.app-container .el-input__inner:focus{
  border-color: rgba(var(--themecolor),0.75);
}
.el-select-dropdown__item.selected{
  color: rgba(var(--themecolor),0.75);
}
</style>