<template>
  <div class="hot-profession">
    <div class="top">
      <h4 class="top-title">热门职业</h4>
      <ul class="top-box">
        <li
          class="top-box-item"
          v-for="(item, index) in pagelist"
          :key="index"
          @click="selectOccupation(item)"
        >
          {{ item.ProfessionName }}
        </li>
      </ul>
    </div>
    <div class="bottom">
      <h4 class="bottom-title">职业推荐</h4>
      <ul class="bottom-box">
        <li
          class="bottom-box-item"
          v-for="(item, index) in datalist2"
          :key="index"
          @click="selectOccupation(item)"
        >
          <img class="bottom-box-item-img" :src="item.CoverImage" />
          <div class="bottom-box-item-name">
            {{ item.ProfessionName }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// import { ProfessionHotList, ProfessionRecommendList } from "@/api/career/profession";
import API from '@/api/config'
export default {
  name: "HotProfession",
  data() {
    return {
      pagelist: null,
      datalist2: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    selectOccupation(val) {
      this.$router.push({
        path: "/profession-detail",
        query: { ID: val.ID },
      });
    },
    init() {
      // 热门职业
      API.Profession.ProfessionHotList({ Number: 10 }).then((res) => {
        this.pagelist = res.data;
      });
      // 推荐职业
      API.Profession.ProfessionRecommendList({ Number: 9 }).then((res) => {
        this.datalist2 = res.data;
      });
    },
  },
};
</script>

<style scoped lang="less">
.hot-profession {
  .top {
    &-title {
      padding: 2rem 0;
      text-align: center;
      font-size: var(--title-font-size);
      color: var(--text-font-color);
    }
    &-box {
      margin: 0 auto;
      padding: 3rem 15rem;
      background-color: #fff;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      &-item {
        margin: 0 1rem 0.5rem 0;
        &:hover {
          cursor: pointer;
          color: rgba(var(--themecolor),0.75);
        }
      }
    }
  }
  .bottom {
    margin-top: 2rem;
    &-title {
      padding: 2rem;
      text-align: center;
      font-size: var(--title-font-size);
      color: var(--text-font-color);
    }
    &-box {
      margin-top: 2rem;
      display: flex;
      flex-wrap: wrap;
      &-item {
        width: calc(100% / 3);
        height: 15rem;
        position: relative;
        &:hover {
          cursor: pointer;
          .bottom-box-item-name {
            background-color: rgba(var(--themecolor),0.6);
          }
        }
        &-img {
          width: 100%;
          height: 100%;
          vertical-align: top;
        }
        &-name {
          height: 3rem;
          line-height: 3rem;
          background-color: rgba(0,0,0,0.4);
          color: #fff;
          text-align: center;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
        }
      }
    }
  }
}
</style>
