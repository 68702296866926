<template>
  <div class="search-profession">
    <h4 class="search-profession-title">按兴趣代码查找</h4>
    <div class="search-profession-content">
      <div class="main" v-for="(item, index) in selectObjectValue" :key="index">
        <span>{{index+1}}-</span>
        <el-select class="main-select" placeholder="请选择代码" v-model="item.value" :clearable="index !== 0">
          <el-option
            v-for="(subItem, subIndex) in options"
            :key="subIndex"
            :label="subItem.label"
            :value="subItem.value"
          >
          </el-option>
        </el-select>
      </div>
      <el-button type="primary" @click="SearchOccupationlist()">搜索</el-button>
    </div>
  </div>
</template>

<script>
  export default {
    name: "SearchProfessionType",
    data(){
      return{
        selectObjectValue:[
          {value:'R'},
          {value:''},
          {value:''},
        ],
        typecode:'',
        options: [
          {
            value: "R",
            label: "R型（实际型）"
          },
          {
            value: "I",
            label: "I型（研究型）"
          },
          {
            value: "A",
            label: "A型（艺术型）"
          },
          {
            value: "S",
            label: "S型（社会型）"
          },
          {
            value: "E",
            label: "E型（企业型）"
          },
          {
            value: "C",
            label: "C型（传统型）"
          }
        ],
      }
    },
    mounted(){
      
    },
    methods:{
     SearchOccupationlist(){
        const Arr = this.selectObjectValue;
         this.typecode = Arr[0].value;
        if(Arr[0].value != Arr[1].value){
          this.typecode+=Arr[1].value
        }
        if(this.value1 != Arr[2].value&&Arr[2].value != Arr[1].value){
           this.typecode+=Arr[2].value
        }
      this.$emit('ok',this.typecode)
    }
    }
  }
</script>

<style scoped lang="less">
  .search-profession{
    width: 65rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-title{
      color: var(--text-font-color);
    }
    &-content{
      display: flex;
      .main{
        margin-right: .5rem;
        &-select{
          width: 11rem;
        }
      }
    }
  }

</style>
